<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
            <path d="M21 4v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2"></path>
            <path d="M3 8h8V6m10 2h-8V6"></path>
        </g>
    </svg>
</template>

<script lang="ts">
export default {
    name: 'BedIcon'
}
</script>